import React, { useState, useEffect } from "react";
import Overlay from "react-overlay-component";

const NestedOverlay = () => {  

  const [isOpen, isModalOpen] = useState(false)
  const [isFirstRun, FirstRun] = useState(true)

  useEffect(() => {
    if(isFirstRun)
    {
        FirstRun(false);
        isModalOpen(true);
    }
  });
  
  const closeModal = () => {
    isModalOpen(false)
  }

  const openModal = () => {
    isModalOpen(true);
  }

  const configs = {
    animate: true,
    showCloseIcon: false,
    focusOutline: true,
    escapeDismiss: false,
    clickDismiss: false
  }

  const [isOpen1, setOverlay1] = useState(false)

  const closeOverlay1 = () => setOverlay1(false)
 
  const configs1 = {
    animate: true,
    showCloseIcon: false,
    focusOutline: true
  }

  return (
    <section >
      
          {/* Nested overlays for the 2 messages when first entering the site */}
      { /*<button className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border" onClick={() => { openModal() }}>open warning modal</button>
        */}
      <Overlay configs={configs} isOpen={isOpen} closeOverlay={closeModal}>
        <Overlay configs={configs1} isOpen={isOpen1} closeOverlay={closeOverlay1}>
            <div style={{ textAlign: "center" }}>
                <p><img className="fluid" src="images/content-images/popup-warn-red.png" /></p>
                <p><strong>Du valgte NEJ, du er ikke</strong></p>
                <p>en voksen me type 2-diabetes, som har fået en recept på Toujeo&reg; SoloStar&reg;, og som er bosiddende i Danmark.</p>
                <p>Luk venligst denne side</p>
                <p><button className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background" >LUK</button></p>
                <p className="mt-70"><strong>Hvis du er</strong></p>
                <p>en voksen me type 2-diabetes, som har fået en recept på Toujeo&reg; SoloStar&reg;, og som er bosiddende i Danmark.</p>
                <p>Tryk på TILBAGE og bekræft ved at vælge JA, for at få adgang til Patientvejledningen.</p>
                <button className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border" onClick={() => { setOverlay1(false) }}>TILBAGE</button>
            </div>
        </Overlay>

        <div style={{ textAlign: "center" }}>
          <p><img className="fluid" src="images/content-images/popup-warn-bw.png" /></p>
          <p><strong>Patientvejledningen er beregnet til voksne med type 2-diabetes, som har fået en recept på Toujeo&reg; SoloStar&reg;, og som er bosiddende i Danmark.</strong> Den er udarbejdet af Sanofi, som en ekstra støtte
          til rådgivningen fra din læge. Disse oplysninger erstatter ikke oplysningerne fra indlægssedlen. Læs altid indlægssedlen, før du bruger Toujeo&reg;.</p>

          <h3 className="mt-40">Toujeo&reg; SoloStar&reg;</h3>
          <p>(insulin glargin 300 enheder/ml)</p>
          <p><img className="fluid" src="images/content-images/popup-logo.png" /></p>
          
          <p className="mt-40">For at få adgang til vejledningen, bede du bekræfte, at du har en recept på Toujeo&reg; SoloStar&reg; Insulin glargin 300 enheder/ml, og bosiddende i Danmark.</p>
          <div className="mt-40">
            <button className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ttm-textcolor-white ttm-bgcolor-background ml-20" onClick={() => { closeModal() }}>JA</button>
            <button className="ttm-btn ttm-btn-size-md rounded-circle ttm-btn-style-border ml-20" onClick={() => { setOverlay1(true) }}>NEJ</button>
          </div>
        </div>
      </Overlay>
    </section>
  )
}
export default NestedOverlay