import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
            {this.props.children}
            
                {/* Bottom of main page */}
                <div className="first-footer">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl">
                    <div className="row align-items-md-center">
                    <div className="col-12">
                        <div className="text-center  ttm-textcolor-white">
                            <p>MAT_DK_2100577 V1.0/1.4.2022<br/>
                            patientvejledningen er beregnet til voksne med type 2-diabetes, som har fået en recept på Toujeo&reg; SoloStar&reg;. Den er udarbejdet af Sanofi, som en ekstra støtte til rådgivningen fra din læge.<br/>
                            Disse oplysninger erstatter ikke oplysningerne fra indlægssedlen. Læs altid indlægssedlen, før du bruger Toujeo&reg; SoloStar&reg;.</p>
                            <div>
                                <img className="img-fluid" src="/images/content-images/sanofi.png"/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* Yellow footer section */}
                <div className="second-footer ttm-textcolor-black ttm-bgcolor-footer1">
                <div className="container-xl">
                    <div className="row">
                    <div className="col-12">
                        <div className="widget widget_text  clearfix">
                        <div className="textwidget widget-text text-center">
                            Læs mere om virkning og bivirkninger i indlægssedlen. Du kan rapportere bivirkninger direkte til Lægemiddelstyrelsen, <a href="http://www.meldenbivirkning.dk/">www.meldenbivirkning.dk.</a><br/>
                            Ved at rapportere bivirkninger kan du hjælpe med at øge informationen om Lægemiddelsikkerhed.
                        </div>


                        </div>
                    </div>
                    </div>
                </div>
                </div>
                
                {/* Bottom Link */}
                <div className="ttm-bgcolor-footer2 ttm-textcolor-white">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="d-lg-inline-flex">
                                    <ul id="menu-footer-menu" className="footer-nav-menu">
                                        <li><a>Fortrolighedspolitik</a></li>
                                        <li><a>Bivirkninger</a></li>
                                        <li><a>Kontact</a></li>
                                        <li><a>Cookie-politik</a></li>
                                        <li><a>Vilkår og betingelser</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}