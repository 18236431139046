import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';

// Layout
import { Footer } from './components/layout/Footer';
// pages
import Main from './components/Main';
//Componenets
import ScrollToTop from './components/layout/Gotop';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {
 
  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>  
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>   
        <ScrollToTop />
            <Route path='/' component={ Main } /> 
        <Footer />
    </Router>  
    </div> 
  );
 }

export default App;
