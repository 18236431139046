import React, { Component } from 'react';
import { MainContent } from './pages/MainContent';

export class Main extends Component {
     render() {
        return (
          <div>
          <MainContent />
          </div>
          
        )
    }
}

export default Main;